@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
@layer utilities {
  .aspect-w-16 {
    aspect-ratio: 16 / 11;
  }
  .aspect-h-9 {
    aspect-ratio: 16/ 13;
  }
}